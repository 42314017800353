<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        class="p-datatable-customers"
        :rows="per_page"
        :totalRecords="totalRows"
        @page="onPage($event)"
        @sort="onSort($event)"
        dataKey="id"
        :rowHover="true"
        :filters="filters"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            List of Sub Admin
            <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <Dropdown v-model="filters.duration" :options="duration"  @change="topFilter($event.value,'duration')" placeholder="Select a type" class="p-column-filter" :showClear="true">
                <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
            </Dropdown> 
            </span> -->
            <router-link to="/sub_admin/add">
              <button class="btn btn-primary">Add Sub Admin</button>
            </router-link>
          </div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column
          header="Name"
          :sortable="true"
          sortField="name"
          filterField="name"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Name</span>

            {{ slotProps.data.name }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.name"
              @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>
        <Column
          header="Email"
          :sortable="true"
          sortField="email"
          filterField="email"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Email</span>

            {{ slotProps.data.email }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.email"
              @keydown.enter="topFilter(filters.email, 'email')"
              class="p-column-filter"
              placeholder="Search by email"
            />
          </template>
        </Column>
        <!-- <Column
          header="Mobile No"
          :sortable="true"
          sortField="mobile"
          filterField="mobile"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Mobile No</span>

            {{ slotProps.data.mobile }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.mobile"
              @keydown.enter="topFilter(filters.mobile, 'mobile')"
              class="p-column-filter"
              placeholder="Search by mobile"
            />
          </template>
        </Column> -->

        <Column
          header="Date"
          :sortable="true"
          sortField="created_at"
          filterField="created_at"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span
              :class="'customer-badge status-' + slotProps.data.created_at"
              >{{
                new Date(slotProps.data.created_at).toLocaleDateString()
              }}</span
            >
          </template>
          <template #filter>
            <Calendar
              v-model="filters.date"
              dateFormat="dd-mm-yy"
              :showButtonBar="true"
              class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')"
              placeholder="Created At"
            />
          </template>
        </Column>

        <Column
          field="user_verify"
          header="Status"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span
              :class="'customer-badge status-' + slotProps.data.user_verify"
              >{{
                slotProps.data.user_verify == 1 ? "verified" : "unverified"
              }}</span
            >
          </template>
          <template #filter>
            <Dropdown
              v-model="filters.status"
              @change="topFilter($event.value, 'status')"
              :options="statuses"
              placeholder="Select a Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <!-- <Dropdown v-model="filters.verify" @change="topFilter($event.value ,'verify')" :options="statuses"  placeholder="Select a Status" class="p-column-filter"   :showClear="true">
                <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
            </Dropdown> --> </template
          >>
        </Column>
        <Column :exportable="false" header="Action">
          <template #body="slotProps">
            <a
              v-b-modal.modal-lg
              class="primary mr-2 ml-2"
              style="cursor: pointer"
              @click="addPer(slotProps.data)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                style="fill: #556ee6"
              >
                <path
                  d="m16 2.012 3 3L16.713 7.3l-3-3zM4 14v3h3l8.299-8.287-3-3zm0 6h16v2H4z"
                ></path>
              </svg>
            </a>
            <a
              v-b-modal.modal-lg
              class="primary mr-2 ml-2"
              style="cursor: pointer"
              @click="viewPer(slotProps.data)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                style="fill: #556ee6"
              >
                <path
                  d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"
                ></path>
                <path
                  d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z"
                ></path>
              </svg>
            </a>
             <span @click="deleteSubadmin('Are you sure you want to delete Sub Admin',slotProps.data.id)"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                     style="fill: #556ee6"
                    >
                      <path
                        d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                      ></path>
                      <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path></svg
                  ></span>
          </template>
        </Column>
      </DataTable>

      <div class="col-sm-6 col-md-4 col-xl-3">
        <div class="my-4 text-center">
          <b-modal
            id="modal-lg"
            size="lg"
            :title="modalType + ' Permission'"
            title-class="font-18"
            hide-footer
          >
            <div v-if="modalType == 'Assign'">
              <form @submit.prevent="save">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.permission_id.$error,
                    }"
                  >
                    <label for="selectpermission_id" class="form-label"
                      >Permissions</label
                    >
                    <div class="input-group mb-3">
                      <multiselect
                        v-model.trim="$v.form.permission_id.$model"
                        track-by="id"
                        label="name"
                        :options="allPermission"
                        :multiple="true"
                        placeholder="Select Permission"
                        value="id"
                      ></multiselect>
                    </div>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.permission_id.required"
                    >
                      Permission is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <div class="col-12 mt-5">
                  <div class="form-group btn-submit">
                    <div class="spinner-border" role="status" v-if="loading1">
                      <span class="visually-hidden">Loading...</span>
                    </div>

                    <button
                      type="submit"
                      class="btn btn-primary"
                      id="submit"
                      v-if="!loading1"
                    >
                      Submit
                    </button>
                  </div>
                  <!---submit button-->
                </div>
              </form>
            </div>

            <div v-if="modalType == 'View'">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">S.no.</th>
                    <th scope="col">Permission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in listPermission" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import ApiClass from "../../api/api";
import { required } from "vuelidate/lib/validators";
import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
// import MultiSelect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,
    Multiselect,
  },
  data() {
    return {
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "Sub Admin",

      items: [
        {
          text: "Dashboards",
          href: "/admin",
        },
        {
          text: "Sub Admin",
          active: true,
        },
      ],
      filters: {
        name: null,
        email: null,
        mobile: null,
        date: null,
        status: null,
        duration: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      loading1: false,

      statuses: ["verified", "unverified"],
      duration: ["day", "week", "month"],
      modalType: "",
      allPermission: [],
      listPermission: [],
      form: {
        user_id: "",
        permission_id: [],
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      permission_id: { required },
    },
  },

  mounted() {
    this.client();
  },

  methods: {
    async client() {
      await ApiClass.getRequest(
        "admin/sub_admin/get?per_page=" +
          this.per_page +
          "&page=" +
          this.page +
          "&" +
          this.filter
      ).then((res) => {
        if (res.data.status_code == 1) {
          this.loading = false;
          this.customers = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.totalRows = res.data.data.total;
        }
      });

      await ApiClass.getRequest("admin/permission/get/sub_admin", true).then(
        (res) => {
          if (res.data.status_code == 1) {
            this.allPermission = res.data.data;
            // console.log(this.allPermission);
          }
        }
      );
    },

    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
        this.client();
      }
    },

    onSort(e) {
      this.loading = !this.loading;
      var sortby = e.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + e.sortField;
      this.client();
    },

    topFilter(columnVal, columnName) {
      //  if(columnName == 'status'){

      //          columnVal = columnVal == 'verified' ? true : false;
      //      }
      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.replaceAll("/", "-");

        columnVal = columnVal.split("-").reverse().join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.client();
    },
    async save() {
      this.submitted = true;

      if (this.$v.$invalid) {
        return;
      }
      this.submitted = false;
      this.loading1 = true;
      let formData = new FormData();

      formData.append("user_id", this.form.user_id);
      this.form.permission_id.forEach((element) => {
        formData.append("permission_id[]", element.id);
      });

      var result = await ApiClass.postRequest(
        "admin/user_permission/create",
        true,
        formData
      );
      if (result.data.status_code == 1) {
        this.loading1 = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
        this.$bvModal.hide("modal-lg");
      } else {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
    addPer(data = null) {
      this.modalType = "Assign";
      this.form.user_id = data.id;
      this.userPer();
    },
    viewPer(data = null) {
      this.modalType = "View";
      this.form.user_id = data.id;
      this.userPer();
    },
    async userPer() {
      var data1 = {
        id: this.form.user_id,
      };

      await ApiClass.getRequest(
        "admin/user_permission/get",
        true,
        null,
        data1
      ).then((res) => {
        // console.log(res);
        if (res.data.status_code == 1) {
          this.form.permission_id = this.listPermission = res.data.data;
        }
      });
    },
    deleteSubadmin(msg, id){
      this.$swal({
                title: "Please Confirm.",
                text: msg,
                icon: "warning",
                iconColor: "#ff0000",
                showCancelButton: true,
                cancelButtonColor: "#ff0000",
                cancelButtonText: "No",
                confirmButtonColor: "#008000",
                confirmButtonText: "Yes",

            }).then((result) => {
                if (result.isConfirmed) {
                  this.apiHit(id);
                }
            })
    },
    async apiHit(id){
      var res = await ApiClass.deleteRequest("admin/sub_admin/delete/" + id, true);
          console.log('resultttt=', res);

            if (res.data.status_code == 1) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1000,
                });
                this.client();
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1000,
                });
            }
    }
  },
};
</script>
<style lang="scss">
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-calendar .p-datepicker {
  width: 350px;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
